/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import SMART_BANNER_SCRIPT from 'src/constants/smartBannerAppsFlyer';

import {
  GTM_ID_MERCHANT,
  GTM_ID_PAIDY_COM,
  titlePathnameMap,
} from '../constants';
import jsonLd from '../json-ld';

function SEO({
  description,
  lang,
  meta,
  title,
  keywords,
  ogImage,
  twitterImage,
  twitterCard,
  pathname,
  audience,
  appendedScripts = [],
  noSEO,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            keywords
            description
            ogImage
            twitterImage
            twitterUsername
            robots
          }
        }
      }
    `
  );

  const titlePrefix = site.siteMetadata.title || 'Paidy';
  const titleFrags = [titlePrefix];

  const isMerchant = pathname.startsWith('/merchant');
  const gtmID = isMerchant ? GTM_ID_MERCHANT : GTM_ID_PAIDY_COM;

  if (titlePathnameMap[pathname]) {
    titleFrags.push(titlePathnameMap[pathname]);
  }

  const seo = {
    title: title || titleFrags.join(' | '),
    description: description || site.siteMetadata.description,
    keywords: keywords || site.siteMetadata.keywords,
    ogImage:
      ogImage || `${site.siteMetadata.siteUrl}${site.siteMetadata.ogImage}`,
    twitterImage:
      twitterImage ||
      `${site.siteMetadata.siteUrl}${site.siteMetadata.twitterImage}`,
    twitterCard: twitterCard || 'summary_large_image',
    twitterUsername: site.siteMetadata.twitterUsername,
  };

  // eslint-disable-next-line no-nested-ternary
  const metaRobots = noSEO
    ? { name: 'robots', content: 'noindex, nofollow' }
    : site.siteMetadata.robots
    ? {
        name: `robots`,
        content: site.siteMetadata.robots,
      }
    : {};

  const script = [
    {
      defer: 'defer',
      innerHTML: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer', '${gtmID}')
        `,
    },
    {
      type: 'application/ld+json',
      innerHTML: JSON.stringify({
        ...jsonLd,
        description: seo.description,
        keywords: seo.keywords,
        headline: seo.title,
        url: `https://paidy.com${pathname}`,
        audience,
      }),
    },
    {
      defer: 'defer',
      innerHTML: `
      (function(d){
        var l = d.createElement("link");
        var s = d.getElementsByTagName("script")[0];
        l.rel = "stylesheet";
        l.href = "https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900&display=swap&subset=japanese";
        s.parentNode.insertBefore(l, s);
      })(document);
      `,
    },
    {
      defer: 'defer',
      innerHTML: `
      (function(d){
        var l = d.createElement("link");
        var s = d.getElementsByTagName("script")[0];
        l.rel = "stylesheet";
        l.href = "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
        s.parentNode.insertBefore(l, s);
      })(document);
      `,
    },
    {
      innerHTML: SMART_BANNER_SCRIPT,
    },
    ...appendedScripts,
  ];

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seo.title}
      meta={[
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: seo.ogImage,
        },
        {
          name: `twitter:card`,
          content: seo.twitterCard,
        },
        {
          property: 'twitter:image',
          content: seo.twitterImage,
        },
        {
          name: `twitter:creator`,
          content: seo.twitterUsername,
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          name: 'impact-site-verification',
          value: '498abf66-6149-46ee-8aed-9ad759c6da4b',
        },
        metaRobots,
      ].concat(meta)}
      link={[
        { rel: 'preconnect', href: 'https://images.ctfassets.net' },
        { rel: 'preconnect', href: 'https://www.google-analytics.com' },
        { rel: 'preconnect', href: 'https://s.yimg.jp' },
        { rel: 'preconnect', href: 'https://bid.g.doubleclick.net' },
        {
          rel: 'preconnect',
          href: 'https://fonts.googleapis.com',
          crossorigin: '',
        },
      ]}
      script={script}
    />
  );
}

SEO.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  title: 'Paidy',
  pathname: '/',
  audience: 'consumer',
};

export default SEO;
